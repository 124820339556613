import { useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { CirclePlus, File, Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Toaster, toast } from "sonner";
import {
    getFiles,
    uploadFile,
    deleteFile,
} from "@/api/client/communications";
import {createComment, deleteComment, getCommunicationById} from "@/api/edit-comunications/edit-comunications.js";
import {useStore} from "@/store/index.js";

const FileUploadAndComments = ({ viewType, isAdmin, communication: propCommunication }) => {
    const params = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para el botón
    const [communication, setCommunication] = useState(propCommunication || null);
    const [comments, setComments] = useState([]);
    const [files, setFiles] = useState([]);
    const { t } = useTranslation();
    const loggedInUser = useStore((state) => state.loggedInUser);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            title: "",
            text: "",
        },
    });

    // Cargar la comunicación si no se proporciona como prop
    useEffect(() => {
        if (!propCommunication) {
            const fetchCommunication = async () => {
                try {
                    const response = await getCommunicationById(params.id);
                    setCommunication(response);
                } catch (error) {
                    console.error("Error al cargar la comunicación:", error);
                }
            };
            fetchCommunication();
        }
    }, [propCommunication, params.id]);

    useEffect(() => {
        loadFiles();
        loadComments();
    }, []);
    const loadFiles = async () => {
        try {
            const response = await getCommunicationById(params.id);
            setFiles(response.files || []); // Asegura que `files` sea un array incluso si está vacío
        } catch (error) {
            console.error("Error al cargar archivos:", error);
        }
    };


    const loadComments = async () => {
        try {
            const response = await getCommunicationById(params.id);
            if (viewType === "public") {
                const publicComments = response.comments?.filter((c) => c.public === true);
                setComments(publicComments);
            } else {
                setComments(response.comments);
            }
        } catch (error) {
            console.error("Error al cargar comentarios:", error);
        }
    };


    useEffect(() => {
        getCommunicationById(params.id).then((response) => {
            if (viewType === "public") {
                const publicComments = response.comments?.filter(
                    (c) => c.public === true,
                )
                setComments(publicComments)
            } else {
                // const privateComments = response.comments?.filter(
                //   (c) => c.public === false,
                // )
                setComments(response.comments)
            }
        })
    }, [viewType, communication])

    const handleFileUpload = async (file, type) => {
        const isPublic = viewType === "public" ? "public" : "";
        try {
            await uploadFile(file, communication.id, isPublic, type);
            await loadFiles(); // Recargar archivos tras el éxito
            toast.success(t("alertDialog.toast.fileUploadSuccess"));
        } catch (error) {
            console.error("Error al subir el archivo:", error);
            toast.error(t("alertDialog.toast.fileUploadError"));
        }
    };


    const handleDeleteFileUploaded = async (id, communicationId) => {
        try {
            await deleteFile(id, communicationId);
            loadFiles(); // Recargar archivos tras el éxito
            toast.success(t("alertDialog.toast.fileDeleteSuccess"));
        } catch (error) {
            console.error("Error al eliminar el archivo:", error);
            toast.error(t("alertDialog.toast.fileDeleteError"));
        }
    };


    const handleDeleteComment = async (id) => {
        try {
            await deleteComment(id);
            setComments((prevComments) => prevComments.filter((c) => c.id !== id));
            toast.success(t("alertDialog.toast.commentDeleteSuccess"));
        } catch (error) {
            console.error("Error al eliminar el comentario:", error);
            toast.error(t("alertDialog.toast.commentDeleteError"));
        }
    };


    const onSubmit = async (data) => {
        const type = viewType === "public";
        const commentSent = {
            ...data,
            public: type,
            communication: { id: communication.id },
            userId: loggedInUser?.id || null
        };
        setIsSubmitting(true);
        try {
            const newComment = await createComment(commentSent);
            setComments((prevComments) => [...prevComments, newComment]);
            toast.success(t("alertDialog.toast.commentCreateSuccess"));
            reset();
        } catch (error) {
            console.error("Error al crear el comentario:", error);
            toast.error(t("alertDialog.toast.commentCreateError"));
        } finally {
            setIsSubmitting(false);
        }
    };
    const filterFiles = (type) => {
        const validFiles = Array.isArray(files) ? files : [];

        const filteredFiles = validFiles.filter(
            (file) => file.public === (viewType === "public") && file.type === type,
        );

        return filteredFiles;
    };


    const truncateFileName = (name, maxLength = 80) => {
        if (name.length > maxLength) {
            return `${name.substring(0, maxLength)}...`;
        }
        return name;
    };

    function formatDate(isoDate) {
        const date = new Date(isoDate)

        const day = String(date.getDate()).padStart(2, "0")
        const month = String(date.getMonth() + 1).padStart(2, "0") // getMonth() devuelve 0-11
        const year = date.getFullYear()

        const hours = String(date.getHours()).padStart(2, "0")
        const minutes = String(date.getMinutes()).padStart(2, "0")

        return `${day}/${month}/${year} ${hours}:${minutes}`
    }

    const renderFiles = (files, isAdmin) => {
        return files && !files.length ? (
            <div className="text-slate-500 ml-2">
                {t("edit.fileUploadAndComments.noFiles")}
            </div>
        ) : (
            files
                ?.sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt))
                .map((file) => (
                    <div className="flex mt-2" key={file.id}>
          <span className="flex items-center text-slate-500 ml-2">
            <a href={file.path} target="_blank" rel="noopener noreferrer">
              <span className="font-semibold">
                {truncateFileName(file.filename)}
              </span>{" "}
                - {formatDate(file.uploadedAt)}
            </a>
          </span>
                        {isAdmin && (
                            <span>
              <div className="flex items-center justify-end">
                <Button
                    className="ml-1 h-2/4 bg-transparent rounded-full hover:bg-red-300"
                    onClick={() => handleDeleteFileUploaded(file.id, communication.id)}
                >
                  <Trash className="w-4 h-4 text-red-500" />
                </Button>
              </div>
            </span>
                        )}
                    </div>
                ))
        );
    };


    const filteredFilesAdmin = filterFiles("admin")
    const filteredFilesFollowUp = filterFiles("followUp")
    const filteredFilesCommunication =
        filteredFilesFollowUp && filteredFilesFollowUp.length
            ? [...filteredFilesFollowUp, ...filterFiles("communication")]
            : filterFiles("communication")

    return (
        <div className="border border-dashed rounded-lg p-6 border-primary animate-fade-in">
            <Toaster/>
            {viewType == "public" && (
                <Card className="mb-4">
                    <CardHeader>
                        <div className="flex p-3 items-center">
            <span className="flex w-5 h-5 mr-1 items-center">
              <File/>
            </span>
                            <span className="flex items-center mr-2">
              {t("edit.fileUploadAndComments.communicationFilesTitle")}
            </span>
                            <span>
              <div className="flex">
                <label className="bg-transparent rounded-full cursor-pointer">
                  <CirclePlus className="w-5 h-5 text-primary"/>
                  <input
                      type="file"
                      className="hidden"
                      onChange={(e) =>
                          handleFileUpload(e.target.files[0], "followUp")
                      }
                  />
                </label>
              </div>
            </span>
                        </div>
                    </CardHeader>
                    <hr/>
                    <CardContent>
                        {renderFiles(filteredFilesCommunication, isAdmin)}
                    </CardContent>
                </Card>
            )}
            {/* <Card className="mb-4">
        <CardHeader>
          <div className="flex p-3 items-center">
            <span className="flex w-5 h-5 mr-1 items-center">
              <File />
            </span>
            <span className="flex items-center mr-2">
              {t("edit.fileUploadAndComments.followUpFilesTitle")}
            </span>
            <span>
              <div className="flex">
                <label className="bg-transparent rounded-full cursor-pointer">
                  <CirclePlus className="w-5 h-5 text-primary" />
                  <input
                    type="file"
                    className="hidden"
                    onChange={(e) =>
                      handleFileUpload(e.target.files[0], "followUp")
                    }
                  />
                </label>
              </div>
            </span>
          </div>
        </CardHeader>
        <hr />
        <CardContent>{renderFiles(filteredFilesFollowUp)}</CardContent>
      </Card> */}

            <Card className="mb-4">
                <CardHeader>
                    <div className="flex p-3 items-center">
            <span className="flex w-5 h-5 mr-1 items-center">
              <File/>
            </span>
                        <span className="flex items-center mr-2">
              {t("edit.fileUploadAndComments.adminFilesTitle")}
            </span>
                        {isAdmin && (
                            <span>
              <div className="flex">
                <label className="bg-transparent rounded-full cursor-pointer">
                  <CirclePlus className="w-5 h-5 text-primary"/>
                  <input
                      type="file"
                      className="hidden"
                      onChange={(e) =>
                          handleFileUpload(e.target.files[0], "admin")
                      }
                  />
                </label>
              </div>
            </span>
                        )}
                    </div>
                </CardHeader>
                <hr/>
                <CardContent>{renderFiles(filteredFilesAdmin, isAdmin)}</CardContent>
            </Card>

            <div className="mb-9">
                <h3>{t("edit.fileUploadAndComments.commentsHeader")}</h3>
                {comments && comments.length ? (
                    <div className="border border-dashed rounded-lg p-6 border-primary animate-fade-in">
                        {comments?.map((comment, index) => (
                            <Card key={index} className="mb-2">
                                <CardHeader className="ml-1">
                                    <div className="flex justify-between">
                                        <div>
                                            {comment.user?.name || comment.user?.email}
                                            {comment.public ? (
                                                <span className="flex text-green-500">
                          ({t("edit.fileUploadAndComments.publicTag")})
                        </span>
                                            ) : (
                                                <span className="flex text-red-500">
                          ({t("edit.fileUploadAndComments.privateTag")})
                        </span>
                                            )}
                                        </div>
                                        <div>
                                            {comment?.date &&
                                                format(
                                                    new Date(comment?.date || ""),
                                                    "dd '-' MM '-' yyyy HH:mm:ss",
                                                    {
                                                        locale: es,
                                                    },
                                                )}
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex">
                                        <div className="w-full">
                                            <h4>{comment.title}</h4>
                                            <p>{comment.text}</p>
                                        </div>
                                        {isAdmin && (
                                            <div className="flex items-center justify-end">
                                                <Button
                                                    className="bg-transparent rounded-full hover:bg-red-300"
                                                    onClick={() =>
                                                        handleDeleteComment(
                                                            comment.id,
                                                            communication.id,
                                                        )
                                                    }
                                                >
                                                    <Trash className="w-5 h-5 text-red-500"/>
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                ) : (
                    <h4 className="ml-5">{t("edit.fileUploadAndComments.noComments")}</h4>
                )}
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                <h3 className="mb-2">{t("edit.fileUploadAndComments.writeComment")}</h3>
                <Input
                    className="mb-2"
                    placeholder={t("edit.fileUploadAndComments.titlePlaceholder")}
                    {...register("title", {required: "Title is required"})}
                />
                {errors.title && (
                    <p className="text-red-500 mb-6">{errors.title.message}</p>
                )}
                <Textarea
                    className="mb-2"
                    placeholder={t("edit.fileUploadAndComments.commentPlaceholder")}
                    {...register("text", {
                        required: "Comment is required",
                    })}
                />
                {errors.text && (
                    <p className="text-red-500 mb-6">{errors.text.message}</p>
                )}
                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (<div className="loader"/>)
                        : t("buttonTexts.sendComment")}
                </Button>
            </form>
        </div>
    )
}

export default FileUploadAndComments;
