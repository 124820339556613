import {Alert} from "@/components/client/alert";
import {TrackForm} from "@/components/client/forms/track-form";
import {buttonVariants} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {cn} from "@/lib/utils";
import {useStore} from "@/store";
import DOMPurify from "dompurify";
import {CircleAlert, CircleHelp, File} from "lucide-react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {NavLink} from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import {setVisit} from "@/api/company/company-services.js";

const ClientPage = () => {
    const {t, i18n} = useTranslation();
    const configurableTexts = useStore((state) => state.configurableTexts);
    const loading = useStore((state) => state.loading);
    const setLoading = useStore((state) => state.setLoading);
    const getHomeFiles = useStore((state) => state.getHomeFiles);
    const homeFiles = useStore((state) => state.homeFiles);
    const [showFiles, setShowFiles] = useState([]);
    const [activeTexts, setActiveTexts] = useState({preText: "", postText: "", title: ""});
    const companyData = useStore((state) => state.companyData);

    useEffect(() => {
        const fetchAndFilterFiles = async () => {
            try {
                await getHomeFiles();
            } catch (error) {
                console.error("Error fetching files:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchAndFilterFiles();
    }, []);

    useEffect(() => {
        if (!homeFiles.length) return;
        const filteredFiles = homeFiles.filter(
            (file) => file.language === i18n.language,
        );
        setShowFiles(filteredFiles);
    }, [homeFiles, i18n.language]);

    useEffect(() => {
        if (!configurableTexts.length) return;

        const getActiveText = (texts) =>
            texts.find((text) => text.language === i18n.language)?.text || "";

        const homeTexts = configurableTexts.find((text) => text.type === "Home");

        setActiveTexts({
            preText: getActiveText(homeTexts.preTexts),
            postText: getActiveText(homeTexts.postTexts),
            title: getActiveText(homeTexts.titles), // Añade el title dinámico
        });
    }, [configurableTexts, i18n.language]);
    useEffect(() => {
        const registerVisit = async () => {
            try {
                await setVisit();
            } catch (error) {
                console.error("Error al registrar la visita:", error);
            }
        };

        registerVisit();
    }, []);



    return (
        <React.Fragment>
            <h1 className="client-title">
                {activeTexts.title || t("ClientLayout.title")}
            </h1>
            <Alert type="info">
                <div
                    className="preText"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(activeTexts?.preText),
                    }}
                />
            </Alert>
            <span
                className="flex-paragraphs postText"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(activeTexts?.postText),
                }}
            />
            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <NavLink
                        className={cn(buttonVariants({variant: "big", size: "big"}))}
                        to="/problem"
                    >
                        <CircleAlert className="sm:h-10 sm:w-10 h-8 w-8"/>
                        <h3 className="text-sm sm:text-lg">{t("ClientLayout.inform")}</h3>
                    </NavLink>
                    {companyData?.hasConsultation && (
                        <NavLink
                            className={cn(buttonVariants({ variant: "big", size: "big" }))}
                            to="/inquiry"
                        >
                            <CircleHelp className="sm:h-10 sm:w-10 h-8 w-8" />
                            <h3 className="text-sm sm:text-lg">
                                {t("ClientLayout.consult")}
                            </h3>
                        </NavLink>
                    )}
                </div>
                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <span className="w-full border-t"/>
                    </div>
                    <div className="relative flex text-center justify-center text-xs md:text-sm uppercase">
            <span className="bg-white px-4 text-muted-foreground z-10">
              {t("ClientLayout.track")}
            </span>
                    </div>
                </div>
                <TrackForm/>

                <div>
                    <Card className="mb-4">
                        <CardHeader>
                            <div className="flex p-3 items-center">
                <span className="flex w-5 h-5 mr-1 items-center">
                  <File/>
                </span>
                                <span className="flex items-center mr-2">
                  {t("ClientLayout.interestDocs.title")}
                </span>
                            </div>
                        </CardHeader>
                        <hr/>
                        <CardContent>
                            {loading ? (
                                <Skeleton count={5}/>
                            ) : showFiles.length > 0 ? (
                                showFiles.map((file) => (
                                    <div className="flex" key={file.id}>
                    <span className="flex items-center text-slate-500 ml-2">
                      <a
                          href={file.path}
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        - {file.title}
                      </a>
                    </span>
                                    </div>
                                ))
                            ) : (
                                <div className="text-slate-500 ml-2">No files</div>
                            )}
                        </CardContent>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ClientPage;
