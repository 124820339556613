import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";

import { zodResolver } from "@hookform/resolvers/zod";

import {
    getForms,
    getQuestionsByForm,
    getConfigurableTexts,
    updateConfigurableTexts,
    updateQuestionsBulk,
} from "@/api/forms-questions/forms-questions-services";
import { KanbanBoard } from "@/components/drag-drop/drag-drop";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autoformat,
    Autosave,
    BlockQuote,
    Bold,
    ClassicEditor,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    Image,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Paragraph,
    PasteFromOffice,
    PictureEditing,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Undo,
    Base64UploadAdapter
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Toaster, toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
    headerText: z.string(),
    footerText: z.string(),
});

const ComplaintFormView = ({ type, formType, selectedLang }) => {
    const [questions, setQuestions] = useState([]);
    const [questionsVisibility, setQuestionsVisibility] = useState([]);
    const [configTexts, setConfigTexts] = useState([]);
    const [currConfigText, setCurrConfigText] = useState([]);
    const [formData, setFormData] = useState([]);
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            headerText: "",
            footerText: "",
        },
    });
    const { setValue } = form;

    useEffect(() => {
        // Filtrar los textos configurables basándose tanto en el tipo como en el formType
        const currentConfigTexts = configTexts.find(
            (text) =>
                text.type.toLowerCase() === type.toLowerCase() &&
                (!text.form || text.form?.type?.toLowerCase() === formType.toLowerCase()) // Ignorar formType si text.form es null
        );

        setCurrConfigText(currentConfigTexts);

        const headerText =
            currentConfigTexts?.preTexts?.find(
                (currText) => currText.language === selectedLang
            )?.text || "";

        const footerText =
            currentConfigTexts?.postTexts?.find(
                (currText) => currText.language === selectedLang
            )?.text || "";

        setValue("headerText", headerText);
        setValue("footerText", footerText);
    }, [configTexts, type, formType, selectedLang]);

    useEffect(() => {
        const fetchConfigTexts = async () => {
            try {
                const configTexts = await getConfigurableTexts();
                setConfigTexts(configTexts);
            } catch (error) {
                console.error("Error fetching form questions:", error);
            }
        };
        fetchConfigTexts();
    }, []);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                // Obtener los formularios
                const forms = await getForms();

                // Buscar el formulario basado en el `formType`
                const form = forms.find((f) => f.type === formType);

                if (form?.id) {
                    // Obtener las preguntas del formulario
                    const data = await getQuestionsByForm(form.id);
                    setFormData(data);

                    // Formatear las preguntas para el KanbanBoard
                    const formattedData = data
                        .filter(
                            (each) => each.active === true
                        )
                        .map((each) => ({
                            content:
                                each.texts.filter((lang) => lang.language === i18n.language)[0]
                                    ?.text || each.alias,
                            columnId: each.visibility?.[type] ? each.visibility[type] : false,
                            id: each.id,
                        }));

                    setQuestions(formattedData);
                } else {
                    console.error(`No se encontró formulario para el tipo: ${formType}`);
                    setQuestions([]); // Si no hay formulario, dejar las preguntas vacías
                }
            } catch (error) {
                console.error("Error al cargar las preguntas del formulario:", error);
            }
        };
        fetchQuestions();
    }, [type, formType, i18n.language]);

    const questionToSend = (item) => {
        return {
            id: item.id,
            form_id: item.form?.id,
            texts: item.texts,
            placeholders: item.placeholders,
            descriptions: item.descriptions,
            type: item.type,
            position: item.position,
            alias: item.alias,
            visibility: item.visibility,
            depends_id: item.depends?.id || null,
        };
    };

    function updateOrAddText(obj, section, language, text) {
        if (!obj[section]) {
            obj[section] = {};
        }

        obj[section][language] = text;
    }

    function mapObject(obj) {
        if (!obj) return {}; // Return empty object if obj is undefined
        return {
            type: obj.type || "", // Defaults to empty string if type is undefined
            preTexts: obj.preTexts
                ? obj.preTexts.reduce((acc, text) => {
                    acc[text.language] = text.text;
                    return acc;
                }, {})
                : {},
            postTexts: obj.postTexts
                ? obj.postTexts.reduce((acc, text) => {
                    acc[text.language] = text.text;
                    return acc;
                }, {})
                : {},
            titles: obj.titles
                ? obj.titles.reduce((acc, text) => {
                    acc[text.language] = text.text;
                    return acc;
                }, {})
                : {}, // Fallback for undefined titles
        };
    }

    const onSubmit = async (values) => {
        const objectToSend = [];
        const final = questionsVisibility.map((elem) => {
            const matchingData = formData.find(
                (currentData) => currentData.id === elem.id
            );

            if (matchingData) {
                if (!matchingData.visibility) {
                    matchingData.visibility = {};
                }
                matchingData.visibility[type] = elem.columnId;
            }

            objectToSend.push(questionToSend(matchingData));
            return matchingData;
        });
        const configTextToSend = mapObject(currConfigText);
        updateOrAddText(
            configTextToSend,
            "preTexts",
            selectedLang,
            values?.headerText || ""
        );
        updateOrAddText(
            configTextToSend,
            "postTexts",
            selectedLang,
            values?.footerText || ""
        );
        try {
            const dataQuestions = await updateQuestionsBulk(objectToSend, type);
            const dataConfigTexts = await updateConfigurableTexts(
                currConfigText?.id,
                configTextToSend
            );
            toast.success(t("alertDialog.toast.updatedSuccess"));
        } catch (error) {
            console.error("Error fetching form data:", error);
        }

        // call api set configurable text
        setFormData(final);
    };

    const editorConfig = {
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "sourceEditing",
                "showBlocks",
                "|",
                "heading",
                "|",
                "fontSize",
                "fontFamily",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "bold",
                "italic",
                "code",
                "|",
                "horizontalLine",
                "link",
                "insertImage",
                "insertTable",
                "blockQuote",
                "codeBlock",
                "htmlEmbed",
                "|",
                "alignment",
                "|",
                "bulletedList",
                "numberedList",
                "outdent",
                "indent",
            ],
            shouldNotGroupWhenFull: true,
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            Autoformat,
            AutoImage,
            AutoLink,
            Autosave,
            Base64UploadAdapter,
            BlockQuote,
            Bold,
            CloudServices,
            Code,
            CodeBlock,
            Essentials,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            GeneralHtmlSupport,
            Heading,
            HorizontalLine,
            HtmlComment,
            HtmlEmbed,
            Image,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            Paragraph,
            PasteFromOffice,
            PictureEditing,
            SelectAll,
            ShowBlocks,
            SourceEditing,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TextTransformation,
            Undo,
        ],
        fontFamily: {
            supportAllValues: true,
        },
        fontSize: {
            options: [10, 12, 14, "default", 18, 20, 22],
            supportAllValues: true,
        },
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },
        htmlSupport: {
            allow: [
                {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true,
                },
            ],
        },
        image: {
            toolbar: [
                "toggleImageCaption",
                "imageTextAlternative",
                "|",
                "imageStyle:inline",
                "imageStyle:wrapText",
                "imageStyle:breakText",
                "|",
                "resizeImage",
            ],
        },
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: "https://",
            decorators: {
                toggleDownloadable: {
                    mode: "manual",
                    label: "Downloadable",
                    attributes: {
                        download: "file",
                    },
                },
            },
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true,
            },
        },
        table: {
            contentToolbar: [
                "tableColumn",
                "tableRow",
                "mergeTableCells",
                "tableProperties",
                "tableCellProperties",
            ],
        },
    };

    return (
        <section className="w-full flex justify-center mt-5">
            <Toaster />
            <Card className="p-6 w-full">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="headerText"
                            render={() => (
                                <FormItem>
                                    <FormLabel>{t("MainLayout.views.header")}</FormLabel>
                                    <FormControl>
                                        <div className="w-full max-w-full overflow-hidden">
                                            <Controller
                                                name="headerText"
                                                control={form.control}
                                                render={({ field }) => (
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={field.value}
                                                        onChange={(_event, editor) => {
                                                            field.onChange(editor.getData());
                                                        }}
                                                        config={editorConfig}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <KanbanBoard
                            data={questions}
                            setData={setQuestionsVisibility}
                        />

                        <FormField
                            control={form.control}
                            name="footerText"
                            render={() => (
                                <FormItem>
                                    <FormLabel>{t("MainLayout.views.footer")}</FormLabel>
                                    <FormControl>
                                        <div className="w-full max-w-full overflow-hidden">
                                            <Controller
                                                name="footerText"
                                                control={form.control}
                                                render={({ field }) => (
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={field.value}
                                                        onChange={(_event, editor) => {
                                                            field.onChange(editor.getData());
                                                        }}
                                                        config={editorConfig}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button
                            type="submit"
                            className="mt-4 bg-primary hover:bg-primary-600"
                        >
                            {t("MainLayout.views.update")}
                        </Button>
                    </form>
                </Form>
            </Card>
        </section>
    );
};
export default ComplaintFormView;
