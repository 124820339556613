import {NavBar} from "@/components/nav-bar";
import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar";
import {UserNav} from "@/components/user-nav";
import {useStore} from "@/store";
import {useEffect} from "react";
import {NavLink, Outlet} from "react-router-dom";

const MainLayout = () => {
    const companyData = useStore((state) => state.companyData);
    const getLanguageList = useStore((state) => state.getLanguageList);
    const getStates = useStore((state) => state.getStates);

    useEffect(() => {
        getStates();
        getLanguageList();
    }, []);

    useEffect(() => {
        if (companyData?.title) {
            document.title = `${companyData.title}`;
        } else {
            document.title = "Canal Ético";
        }
    }, [companyData?.name]);

    return (
        <section className="mx-auto max-w-screen-3xl">
            <nav class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                <aside className="flex grow flex-col gap-y-6 overflow-y-auto border-r border-slate-200 p-4">
                    <NavLink
                        to="/"
                        className="flex w-full flex-col items-center gap-y-2 rounded-lg border border-slate-200 p-4 text-sm"
                    >
                        <div className="flex items-center justify-center w-full max-w-[180px]">
                            <Avatar className="w-full h-auto">
                                {companyData?.logo ? (
                                    <AvatarImage
                                        src={companyData.logo}
                                        alt={companyData?.name || "Company logo"}
                                        className="w-full h-auto object-contain"
                                    />
                                ) : (
                                    <AvatarFallback>
                                        {companyData?.name
                                            ?.split(" ")
                                            .map((word) => word[0])
                                            .join("")
                                            .slice(0, 2)
                                            .toUpperCase()}
                                    </AvatarFallback>
                                )}
                            </Avatar>
                        </div>
                    </NavLink>
                    <NavBar companyData={companyData} />
                    <div className="mt-auto">
                        <UserNav/>
                    </div>
                </aside>

            </nav>
            <main className="lg:pl-72">
                <div className="relative">
                    <div className="p-4 sm:px-6 sm:pb-10 sm:pt-10 lg:px-8 lg:pt-7">
                        <Outlet/>
                    </div>
                </div>
            </main>
        </section>
    );
};

export default MainLayout;
