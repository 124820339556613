import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { useStore } from "@/store/use-store";
import { Globe } from "lucide-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LangSelect = () => {
  const { i18n } = useTranslation();
  const {
    languageList,
    selectedLanguage,
    getLanguageList,
    setSelectedLanguage,
  } = useStore((state) => ({
    languageList: state.languageList,
    selectedLanguage: state.selectedLanguage,
    getLanguageList: state.getLanguageList,
    setSelectedLanguage: state.setSelectedLanguage,
  }));

  useEffect(() => {
    // Obtener el parámetro `lang` de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const langFromUrl = urlParams.get("lang");

    if (langFromUrl) {
      // Si existe el parámetro `lang`, forzar el idioma correspondiente
      const lowerCaseLang = langFromUrl.toLowerCase();
      i18n.changeLanguage(lowerCaseLang);
      setSelectedLanguage(lowerCaseLang);
      console.log(`Idioma forzado desde la URL: ${lowerCaseLang}`);
    } else if (!selectedLanguage) {
      // Si no hay idioma seleccionado, establecer "es" como predeterminado
      i18n.changeLanguage("es");
      setSelectedLanguage("es");
      console.log("Idioma predeterminado establecido a: es");
    }

    // Si el languageList está vacío, obtener los idiomas de la API
    if (!languageList || Object.keys(languageList).length === 0) {
      getLanguageList();
    }
  }, [languageList, getLanguageList, i18n, selectedLanguage, setSelectedLanguage]);

  const changeLanguage = (lng) => {
    const lowerCaseLng = lng.toLowerCase(); // Convertir el código de idioma a minúsculas
    i18n.changeLanguage(lowerCaseLng);
    setSelectedLanguage(lowerCaseLng); // Actualizar el estado global con el idioma en minúsculas
    // Trazar el idioma seleccionado al cambiar
    console.log(`Idioma cambiado a: ${lowerCaseLng}`);
  };

  // Manejar cuando languageList sea null o esté vacío
  if (!languageList || Object.keys(languageList).length === 0) {
    return null; // O mostrar un loading spinner si prefieres
  }

  return (
      <Menubar className="bg-transparent">
        <MenubarMenu>
          <MenubarTrigger className="rounded-full p-2 hover:bg-accent">
            <Globe width={18} height={18} />
          </MenubarTrigger>
          <MenubarContent className="w-14 !min-w-0">
            {languageList
                .filter((language) => language.active) // Filtrar solo los idiomas activos
                .map((language) => (
                    <MenubarItem
                        key={language.code}
                        className={`w-full text-center ${
                            i18n.language === language.code ? "text-gray-400 " : "active"
                        }`}
                        onClick={() => changeLanguage(language.code)}
                    >
                      <span className="w-full">{language.code.toUpperCase()}</span>{" "}
                      {/* Mostrar el código del idioma */}
                    </MenubarItem>
                ))}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
  );
};

export default LangSelect;
