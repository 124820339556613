import { getComplaintsExcel } from "@/api/edit-comunications/edit-comunications";
import { KPICards } from "@/components/kpi-cards";
import { Button } from "@/components/ui/button";
import { getColumns } from "@/components/ui/table/columns/communications";
import { priorities } from "@/components/ui/table/data-table-filters.jsx";
import { DataTable } from "@/components/ui/table/data-table.jsx";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import Loader from "@/components/utils/loader";
import { buttonVariants } from "@/components/ui/button";
import { calculateKpis } from "@/lib/utils";
import { useStore } from "@/store";
import { CloudDownload } from "lucide-react";
import { UndoDot } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { CircleAlert } from "lucide-react";
import { cn } from "@/lib/utils";
import { getComplaints } from "@/api/client/communications.js";

const ComplaintPage = () => {
    const selectedLanguage = useStore((state) => state.selectedLanguage);
    const states = useStore((state) => state.states); // Mover aquí para usar en el componente
    const [complaints, setComplaints] = useState([]);
    const { t } = useTranslation();
    const [filterState, setFilterState] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch complaints from API and set state
        const fetchData = async () => {
            try {
                const response = await getComplaints();
                setComplaints(response.data || []);
            } catch (error) {
                console.error("Error con la carga de complaints:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const cardTypes = useMemo(() => {
        const complaintsCard = states
            ?.filter((state) => state.type === "complaint")
            ?.sort((a, b) => a.order - b.order);

        return complaintsCard?.map((item) => ({
            id: item.id,
            name:
                item.names.find((name) => name.language === selectedLanguage)?.text ||
                "not this language",
            color: item.color,
        }));
    }, [states, selectedLanguage]);

    const complaintsKpis = useMemo(
        () => calculateKpis(complaints, cardTypes),
        [complaints, cardTypes]
    );

    const filteredComplaints = useMemo(() => {
        if (!filterState) return complaints;
        return complaints.filter((complaint) => {
            const esStateName = complaint.state?.names?.find(
                (name) => name.language === "es"
            )?.text;
            return esStateName === filterState;
        });
    }, [complaints, filterState]);

    const handleCardClick = (id) => {
        setFilterState(id);
    };

    const handleReset = () => {
        setFilterState(null);
    };

    const columns = useMemo(
        () => getColumns(filteredComplaints, selectedLanguage),
        [filteredComplaints, selectedLanguage]
    );

    const handleDownloadExcel = async () => {
        try {
            const data = await getComplaintsExcel();
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "denuncias.xlsx");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Error al descargar el archivo Excel:", error);
        }
    };

    // Mostrar loader mientras loading es true
    if (loading) {
        return (
            <div className="flex justify-center h-screen">
                <Loader style={"loader"} />
            </div>
        );
    }

    // Renderizar contenido principal una vez que los datos están listos
    return (
        <div className="space-y-4 sm:space-y-6 lg:space-y-10">
            <section className="flex flex-col gap-6">
                <h1>{t("MainLayout.complaintOverviewTitle")}</h1>
                <KPICards
                    data={complaintsKpis}
                    onCardClick={handleCardClick}
                    cardEntity={{ fontColor: "#64748B" }}
                />
            </section>
            <section className="flex flex-col gap-6">
                <div className="flex items-center">
                    <h1 className="mr-2">{t("MainLayout.complaintHeaderTitle")}</h1>
                    {filterState !== null && (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger>
                                    <Button
                                        className="bg-primary rounded-full"
                                        onClick={handleReset}
                                    >
                                        <UndoDot />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>{t("tooltip.clearFilter")}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )}
                    <NavLink
                        className={cn(buttonVariants({ variant: "default", size: "sm" }))}
                        to="/admin/complaints/create"
                    >
                        <CircleAlert className="h-6 w-6 mr-2" />
                        <h3 className="text-base">{t("buttonTexts.createComplaint")}</h3>
                    </NavLink>
                    <Button
                        type="button"
                        className="ml-auto w-full md:w-fit flex gap-1.5 items-center"
                        icon={CloudDownload}
                        onClick={handleDownloadExcel}
                    >
                        {t("buttonTexts.recordBook")}
                    </Button>
                </div>
                <DataTable
                    data={filteredComplaints}
                    columns={columns}
                    mainFilterColumn="identificationNumber"
                    filterOptions={[
                        {
                            column: "risk",
                            title: t("headColumns.communications.priority"),
                            options: priorities,
                        },
                    ]}
                    textPlaceholder={t("MainLayout.complaintPageOverview.filterText")}
                />
            </section>
        </div>
    );
};

export default ComplaintPage;
