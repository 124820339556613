import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { getLanguages } from "@/api/languages/languages-services";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  texts: z.record(
    z.string(),
    z.string().min(1, { message: "Este campo es requerido" }),
  ),
  descriptions: z.record(z.string(), z.string().optional()),
  placeholders: z.record(z.string(), z.string().optional()),
  screen: z
    .number()
    .min(1, { message: "Screen is required" })
    .nullable()
    .optional(),
  type: z.string().min(1, { message: "Este campo es requerido" }),
  optionsText: z.record(z.string(), z.array(z.string().optional())).optional(),
  active: z.boolean().optional(),
  required: z.boolean().optional(),
  depends_id: z.number().nullable().optional(),
  related_question: z.number().nullable().optional()
});

const UpdateQuestion = ({ formType, closeModal }) => {
  const { t, i18n } = useTranslation();

  const [questionLanguages, setQuestionLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const complaintQuestions = useStore((state) => state.complaintQuestions);
  const consultationQuestions = useStore(
    (state) => state.consultationQuestions,
  );

  const questionToUpdateData = useStore((state) => state.questionToUpdateData);
  const UpdatetQuestion = useStore((state) => state.UpdatetQuestion);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      texts: {},
      descriptions: {},
      placeholders: {},
      screen: null,
      type: "",
      optionsText: {},
      active: false,
      required: false,
      depends_id: null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `optionsText.${selectedLanguage}`,
  });

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languages = await getLanguages();
        setQuestionLanguages(languages);

        const defaultLanguage = i18n.language;

        const normalizedDefaultLanguage = defaultLanguage.split("-")[0];

        const foundLanguage = languages.find(
          (lang) => lang.code === normalizedDefaultLanguage,
        );

        if (foundLanguage) {
          setSelectedLanguage(normalizedDefaultLanguage);
        } else {
          setSelectedLanguage(languages[0]?.code || "");
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, [i18n.language]);

  useEffect(() => {
    const fetchQuestionToUpdate = async () => {
      if (questionToUpdateData) {
        try {

          form.reset({
            texts: questionToUpdateData.texts.reduce((acc, text) => {
              acc[text.language] = text.text;
              return acc;
            }, {}),
            descriptions: questionToUpdateData.descriptions.reduce((acc, desc) => {
              acc[desc.language] = desc.text;
              return acc;
            }, {}),
            placeholders: questionToUpdateData.placeholders.reduce((acc, placeholder) => {
              acc[placeholder.language] = placeholder.text;
              return acc;
            }, {}),
            screen: questionToUpdateData.screen,
            type: questionToUpdateData.type,
            optionsText: questionToUpdateData.options.reduce((acc, option) => {
              for (const text of option.text) {
                if (!acc[text.language]) {
                  acc[text.language] = [];
                }
                acc[text.language].push(text.text);
              }
              return acc;
            }, {}),
            active: questionToUpdateData.active,
            required: questionToUpdateData.required,
            depends_id: questionToUpdateData.depends ? questionToUpdateData.depends.id : null,
            related_question: null
          });


          // If depends_id exists, we look for the related question
          if (questionToUpdateData.depends && questionToUpdateData.depends.id) {
            const relatedOptionId = questionToUpdateData.depends.id;
            const allQuestions = formType === "complaint" ? complaintQuestions : consultationQuestions;


            const foundQuestion = allQuestions.find(q =>
                q.options && q.options.some(o => o.id === relatedOptionId)
            );


            if (foundQuestion) {
              // Set the related question
              setSelectedQuestion(foundQuestion);
              form.setValue("related_question", foundQuestion.id);
            }
          }
        } catch (error) {
          console.error("Error fetching question to update:", error);
        }
      }
    };
    fetchQuestionToUpdate();
  }, [questionToUpdateData, form, complaintQuestions, consultationQuestions, formType]);

  useEffect(() => {
    if (questionToUpdateData && selectedLanguage) {
      const texts = questionToUpdateData.texts || [];
      const descriptions = questionToUpdateData.descriptions || [];
      const placeholders = questionToUpdateData.placeholders || [];
      const options = questionToUpdateData.options || [];

      form.setValue("texts", {
        ...form.getValues("texts"),
        [selectedLanguage]:
          texts.find((t) => t.language === selectedLanguage)?.text || "",
      });

      form.setValue("descriptions", {
        ...form.getValues("descriptions"),
        [selectedLanguage]:
          descriptions.find((d) => d.language === selectedLanguage)?.text || "",
      });

      form.setValue("placeholders", {
        ...form.getValues("placeholders"),
        [selectedLanguage]:
          placeholders.find((p) => p.language === selectedLanguage)?.text || "",
      });

      form.setValue("optionsText", {
        ...form.getValues("optionsText"),
        [selectedLanguage]: options.map(
          (option) =>
            option.text?.find((t) => t.language === selectedLanguage)?.text ||
            "",
        ),
      });
    }
  }, [selectedLanguage, questionToUpdateData, form]);

  useEffect(() => {
    if (selectedLanguage) {
      const currentOptions =
        form.getValues(`optionsText.${selectedLanguage}`) || [];
      remove();
      for (const option of currentOptions) {
        append(option);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const addOption = () => {
    append("");
  };

  const getTextByLanguage = (texts, language) => {
    if (!Array.isArray(texts)) {
      return "";
    }
    const textObj = texts.find((text) => text.language === language);
    return textObj ? textObj.text : "";
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const updateQuestionToSend = {
      form_id: questionToUpdateData.form.id,
      texts: values.texts,
      options: Object.keys(values.optionsText[selectedLanguage] || {}).map(
        (key, index) => ({
          id: questionToUpdateData.options[index]?.id || null,
          text: questionLanguages.reduce((acc, lang) => {
            acc[lang.code] = {
              text: values.optionsText[lang.code]?.[key] || "",
            };
            return acc;
          }, {}),
          value: index.toString(),
          position: index,
        }),
      ),
      type: values.type,
      screen: values.screen,
      active: values.active,
      required: values.required,
      depends_id: values.depends_id,
    };

    try {
      await UpdatetQuestion(
        questionToUpdateData.id,
        updateQuestionToSend,
        formType,
      );
      toast.success(t("alertDialog.toast.questionUpdatedSuccess"));
      closeModal();
    } catch (error) {
      console.error("Error al actualizar la pregunta:", error);
      toast.error(t("alertDialog.toast.questionUpdatedError"));
    } finally {
      setLoading(false);
    }
  };

  const questions =
    formType === "complaint" ? complaintQuestions : consultationQuestions;

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full text-black bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.privacyPoicy.language")}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      setSelectedLanguage(value);
                    }}
                    value={selectedLanguage}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Seleccionar idioma" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {questionLanguages.map((language) => (
                        <SelectItem key={language.id} value={language.code}>
                          {language.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {selectedLanguage && (
              <>
                <FormField
                  control={form.control}
                  name={`texts.${selectedLanguage}`}
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>
                        {t("forms.questions.question")} (
                        {selectedLanguage.toUpperCase()})
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`descriptions.${selectedLanguage}`}
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>
                        {t("forms.questions.description")} (
                        {selectedLanguage.toUpperCase()})
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`placeholders.${selectedLanguage}`}
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>
                        {t("forms.questions.placeholder")} (
                        {selectedLanguage.toUpperCase()})
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}

            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2 space-y-0 mb-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id="active"
                    />
                  </FormControl>
                  <FormLabel
                    htmlFor="active"
                    className="text-sm font-medium leading-none cursor-pointer"
                  >
                    {t("forms.questions.activate")}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="required"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2 space-y-0 mb-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id="required"
                    />
                  </FormControl>
                  <FormLabel
                    htmlFor="required"
                    className="text-sm font-medium leading-none cursor-pointer"
                  >
                    {t("forms.questions.required")}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="screen"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.questions.position")}</FormLabel>
                  <Select
                    onValueChange={(value) =>
                      field.onChange(value === "null" ? null : Number(value))
                    }
                    value={
                      field.value === null ? "null" : field.value.toString()
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select screen" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="null">Ninguna</SelectItem>
                      <SelectItem value="1">
                        {t("forms.questions.screen")} 1
                      </SelectItem>
                      <SelectItem value="2">
                        {t("forms.questions.screen")} 2
                      </SelectItem>
                      <SelectItem value="3">
                        {t("forms.questions.screen")} 3
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.questions.questionType")}</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecciona tipo de pregunta" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="select">Select</SelectItem>
                      <SelectItem value="radio">Radio</SelectItem>
                      <SelectItem value="input">Input</SelectItem>
                      <SelectItem value="textArea">TextArea</SelectItem>
                      <SelectItem value="email">Email</SelectItem>
                      <SelectItem value="terms">Términos</SelectItem>
                      <SelectItem value="file">Fichero</SelectItem>
                      <SelectItem value="password">Contraseña</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {(form.watch("type") === "select" ||
              form.watch("type") === "radio") && (
              <div className="space-y-4">
                <div className="flex justify-between items-center mt-2">
                  <FormLabel>{t("forms.questions.options")}</FormLabel>
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={addOption}
                  >
                    {t("forms.questions.addOption")}
                  </Button>
                </div>

                {fields.map((field, index) => (
                  <div key={field.id} className="flex space-x-2">
                    <FormField
                      control={form.control}
                      name={`optionsText.${selectedLanguage}.${index}`}
                      render={({ field }) => (
                        <FormItem className="flex-1 mb-4">
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={`${selectedLanguage.toUpperCase()} ${t(
                                "forms.questions.option",
                              )}`}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <Button
                      type="button"
                      variant="destructive"
                      size="sm"
                      onClick={() => remove(index)}
                    >
                      {t("forms.questions.remove")}
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <FormField
                control={form.control}
                name="related_question"
                render={({ field }) => {
                  return (
                      <FormItem className="mb-4">
                        <FormLabel>{t("forms.questions.relatedQuestion")}</FormLabel>
                        <Select
                            onValueChange={(value) => {
                              const selectedQ = questions.find((question) => question.id === Number(value));
                              field.onChange(Number(value));
                              setSelectedQuestion(selectedQ);
                            }}
                            value={field.value ? field.value.toString() : ""}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Selecciona la pregunta relacionada" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {questions.map((question) => {
                              return (
                                  <SelectItem
                                      key={question.id}
                                      value={question.id.toString()}
                                  >
                                    {getTextByLanguage(question.texts, selectedLanguage) || question.alias}
                                  </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                  );
                }}
            />


            {selectedQuestion && (
                <FormField
                    control={form.control}
                    name="depends_id"
                    render={({ field }) => (
                        <FormItem className="mb-4">
                          <FormLabel>{t("forms.questions.optionRelated")}</FormLabel>
                          <Select
                              onValueChange={(value) => {
                                field.onChange(Number(value));
                              }}
                              value={field.value ? field.value.toString() : ""}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Selecciona la opción relacionada" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {selectedQuestion.options.map((option) => (
                                  <SelectItem
                                      key={option.id}
                                      value={option.id.toString()}
                                  >
                                    {getTextByLanguage(option.text, selectedLanguage) || "no tiene opciones"}
                                  </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                    )}
                />
            )}


            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {loading ? (
                <Loader style={"buttonLoader"} />
              ) : (
                t("buttonTexts.saveChanges")
              )}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};

export default UpdateQuestion;
