import { getConsultationsExcel } from "@/api/edit-comunications/edit-comunications";
import { KPICards } from "@/components/kpi-cards";
import { Button } from "@/components/ui/button";
import { getColumns } from "@/components/ui/table/columns/communications"; // Importamos getColumns
import { priorities } from "@/components/ui/table/data-table-filters.jsx";
import { DataTable } from "@/components/ui/table/data-table.jsx";
import { NavLink } from "react-router-dom";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { CircleHelp } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import Loader from "@/components/utils/loader";
import { calculateKpis } from "@/lib/utils";
import { useStore } from "@/store";
import { CloudDownload, Plus } from "lucide-react";
import { UndoDot } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getInquiries as fetchInquiries } from "@/api/client/communications"; // Importamos la función de la API

const QueriesPage = () => {
  const selectedLanguage = useStore((state) => state.selectedLanguage);
  const { t } = useTranslation();
  const [filterState, setFilterState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inquiries, setInquiries] = useState([]); // Cambiado: estado local para inquiries

  const consultationsCardTypes = () => {
    const states = useStore((state) => state.states);
    const consultationsCard = states?.filter(
        (state) => state.type === "consultation"
    );

    return consultationsCard.map((item) => ({
      id: item.id,
      name:
          item.names.find((name) => name.language === selectedLanguage)?.text ||
          "not this language",
      color: item.color,
    }));
  };

  const cardTypes = consultationsCardTypes();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchInquiries();
        setInquiries(response.data);
      } catch (error) {
        console.error("Error con la carga", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Elimina la dependencia de getInquiries del store

  const inquiriesKpis = useMemo(
      () => calculateKpis(inquiries, cardTypes),
      [inquiries, cardTypes]
  );

  const filteredInquiries = useMemo(() => {
    if (!filterState) return inquiries;
    return inquiries.filter((inquire) => {
      const esStateName = inquire.state?.names?.find(
          (name) => name.language === "es"
      )?.text;
      return esStateName === filterState;
    });
  }, [inquiries, filterState]);

  const handleCardClick = (id) => {
    setFilterState(id);
  };

  const handleReset = () => {
    setFilterState(null);
  };

  if (loading) {
    return (
        <div className="flex justify-center h-screen">
          <Loader style={"loader"} />
        </div>
    );
  }

  // Generar columnas utilizando getColumns para las columnas dinámicas
  const columns = getColumns(filteredInquiries, selectedLanguage);

  const handleDownloadExcel = async () => {
    try {
      const data = await getConsultationsExcel();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "consultas.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo Excel:", error);
    }
  };

  return (
      <div className="space-y-4 sm:space-y-6 lg:space-y-10">
        <section className="flex flex-col gap-6">
          <h1>{t("MainLayout.queriesOverviewTitle")}</h1>
          <KPICards
              data={inquiriesKpis}
              onCardClick={handleCardClick}
              cardEntity={{ fontColor: "#ffffff" }}
          />
        </section>
        <section className="flex flex-col gap-6">
          <div className="flex items-center">
            <h1 className="mr-2">{t("MainLayout.queriesHeaderTitle")}</h1>
            {filterState !== null && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                          className="bg-primary rounded-full"
                          onClick={handleReset}
                      >
                        <UndoDot />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("tooltip.clearFilter")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
            )}
            <NavLink
                className={cn(buttonVariants({ variant: "default", size: "sm" }))}
                to="/admin/queries/create" // Nueva ruta dentro del panel de control
            >
              <CircleHelp className="h-6 w-6 mr-2" />
              <h3 className="text-base">{t("buttonTexts.createQuery")}</h3>
            </NavLink>
            <Button
                type="button"
                className="ml-auto w-full md:w-fit flex gap-1.5 items-center"
                icon={CloudDownload}
                onClick={handleDownloadExcel}
            >
              {t("buttonTexts.recordBook")}
            </Button>
          </div>
          <DataTable
              data={filteredInquiries}
              columns={columns} // Usamos columns generado dinámicamente
              mainFilterColumn="identificationNumber"
              filterOptions={[
                {
                  column: "risk",
                  title: t("headColumns.communications.priority"),
                  options: priorities,
                },
              ]}
              textPlaceholder={t("MainLayout.consultationPageOverview.filterText")}
          />
        </section>
      </div>
  );
};

export default QueriesPage;
