import {
  blockCommunication, deleteCommunication,
  downloadExcel,
  downloadPDF,
  extendCommunicationDeadline, unblockCommunication,
} from "@/api/client/communications.js";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Dialog, DialogContent, DialogOverlay } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { DataTableColumnHeader } from "@/components/ui/table/data-table-column-header";
import { DataTableRowActions } from "@/components/ui/table/data-table-row-actions";
import Toast from "@/components/ui/toast.jsx";
import { useStore } from "@/store";
import { addDays, differenceInDays, format, isValid, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { t } from "i18next";
import { FileSpreadsheet, FileText, Pencil, UserPlus,Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { assignCommunications } from "@/api/users/users-services";


// Columnas base estáticas
const baseColumns = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Id" />
    ),
    cell: ({ row }) => (
      <div className="w-[10px] text-center">{row.getValue("id")}</div>
    ),
    enableSorting: true,
  },
  {
    accessorKey: "identificationNumber",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("headColumns.communications.trackingNumber")}
      />
    ),
    cell: ({ row }) => (
      <div className="text-center">
        {row.getValue("identificationNumber") || "-"}
      </div>
    ),
    filterFn: "includesString",
    enableSorting: true,
  },
  {
    accessorKey: "type",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("headColumns.communications.communicationType")}
      />
    ),
    cell: ({ row }) => {
      const type = row.getValue("type");
      const translatedType = t(`communicationTypes.${type}`, type);
      return (
        <Badge className="flex justify-center items-center mx-auto text-center">
          {translatedType}
        </Badge>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: "date",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("headColumns.communications.createDate")}
      />
    ),
    cell: ({ row }) => {
      const dateValue = row.getValue("date");
      const formattedDate = dateValue
        ? isValid(parseISO(dateValue))
          ? format(parseISO(dateValue), "dd-MM-yyyy", { locale: es })
          : "Fecha no válida"
        : "Fecha no válida";

      return <div className="w-[100px] text-center">{formattedDate}</div>;
    },
    enableSorting: true,
  },
  {
    accessorKey: "limitDate",
    header: ({ column }) => (
        <DataTableColumnHeader
            column={column}
            title={t("headColumns.communications.limitDate")}
        />
    ),
    cell: ({ row }) => {
      const date = row.original.date;
      const extendedDate = row.original.extendedDate;
      const type = row.original.type;
      const companyData = useStore((state) => state.companyData); // Acceder al estado del store

      let dateValue = date;

      // Verificar si extendedDate no es null ni undefined
      if (extendedDate) {
        dateValue = extendedDate;
      }

      if (!dateValue) {
        return <div className="w-[100px] text-center">Fecha no válida</div>;
      }

      const parsedDate = parseISO(dateValue);
      let daysToAdd = 120; // Valor por defecto
      if (type === "consultation") {
        if (companyData?.altSolveTimeLimit) {
          daysToAdd = companyData?.altSolveTimeLimit;
        }
      }else {
        daysToAdd = companyData?.solveTimeLimit || 90;
      }
      const targetDate = addDays(parsedDate, daysToAdd);
      const daysRemaining = Math.max(
          differenceInDays(targetDate, new Date()),
          0
      );
      const [greenLimit, yellowLimit] = companyData?.dateLimitColors || [60, 30];

      const colors =
          daysRemaining > greenLimit
              ? { bg: "bg-green-500", text: "text-black" }
              : daysRemaining > yellowLimit
                  ? { bg: "bg-yellow-500", text: "text-black" }
                  : { bg: "bg-red-500", text: "text-white" };

      return (
          <div
              className={`w-[100px] p-2 rounded ${colors.bg} ${colors.text} text-center`}
          >
            {daysRemaining} días
          </div>
      );
    },
    enableSorting: true,
  },


  {
    accessorKey: "state",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("headColumns.communications.state")}
      />
    ),
    cell: ({ row }) => {
      const status = row.getValue("state");
      const selectedLanguage = useStore((state) => state.selectedLanguage);
      const [statusText, setStatusText] = useState("");

      useEffect(() => {
        const text =
          status?.names.find((name) => name.language === selectedLanguage)
            ?.text || "no lang";
        setStatusText(text);
      }, [selectedLanguage, status?.names]);

      return (
        <div
          className="flex w-full h-full items-center justify-center"
          style={{
            backgroundColor: status?.color || "transparent",
            borderRadius: "8px",
            color: "white",
          }}
        >
          {status?.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span className="text-center">{statusText}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: "risk",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("headColumns.communications.priority")}
      />
    ),
    cell: ({ row }) => {
      const risk = row.getValue("risk");
      const translatedRisk = t(`risk.${risk}`)
      return (
        <div className="flex w-[80px] items-center justify-center">{translatedRisk}</div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: "source",
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("headColumns.communications.source")} />
    ),
    cell: ({ row }) => {
      const sourceValue = row.getValue("source") || 'public';
      const translatedSource = t(`sources.${sourceValue}`);
      return (
          <div className="text-center">{translatedSource}</div>
      );
    },
    enableSorting: true,
    filterFn: "includesString", // Opcional: agrega filtros si es necesario
  },
  {
    accessorKey: "tags",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t("headColumns.communications.tags")} />
    ),
    cell: ({ row }) => {
      const tags = row.getValue("tags") || [];
      return (
        <div className="flex flex-wrap justify-center gap-1">
          {tags.map((tag) => (
            <Badge key={tag.id} className="bg-blue-500 text-white">
              {tag.text}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    accessorKey: "solveDate",
    header: ({ column }) => (
        <DataTableColumnHeader
            column={column}
            title={t("headColumns.communications.solveDate")}
        />
    ),
    cell: ({ row }) => {
      const solveDate = row.getValue("solveDate");
      const formattedDate = solveDate
          ? isValid(parseISO(solveDate))
              ? format(parseISO(solveDate), "dd-MM-yyyy", { locale: es })
              : t("messages.invalidDate")
          : "";

      return <div className="w-[100px] text-center">{formattedDate}</div>;
    },
    enableSorting: true,
  },
];

const actionsColumn = {
  id: "actions",
  cell: ({ row }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false); // Para el popup de eliminación
    const [isExtendDialogOpen, setIsExtendDialogOpen] = useState(false); // Para el popup de extensión de plazo
    const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false); // Para el popup de asignación
    const [isBlockDialogOpen, setIsBlockDialogOpen] = useState(false); // Para el popup de bloqueo
    const [reason, setReason] = useState("");
    const [usereditUsers, setUsereditUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const { id, type, extendedDate, isBlocked } = row.original;


    // Leer el usuario logueado y sus roles desde el store
    const loggedInUser = useStore((state) => state.loggedInUser);
    const userRoles = loggedInUser.roles || [];
    const isSuperadmin = userRoles.includes("superadmin");


    // Determinar si se puede mostrar la acción "Asignar a Useredit"
    const canAssignToUseredit =
        userRoles.includes("admin") || userRoles.includes("superadmin");

    const handleBlock = async () => {
      try {
        await blockCommunication(id);
        console.log(`Comunicación ${id} bloqueada.`);
        setIsBlockDialogOpen(false); // Cierra el popup después de confirmar
        window.location.reload();
      } catch (error) {
        console.error(`Error al bloquear la comunicación ${id}:`, error);
      }
    };

    const handleUnblock = async () => {
      try {
        await unblockCommunication(id);
        console.log(`Comunicación ${id} desbloqueada.`);
        window.location.reload();
      } catch (error) {
        console.error(`Error al desbloquear la comunicación ${id}:`, error);
      }
    };

    const handleDeleteClick = () => setIsDialogOpen(true);

    const confirmDelete = async () => {
      try{
        await deleteCommunication(id, type);
        setIsDialogOpen(false);
        window.location.reload();
      } catch (error){
        console.error(`Error al borrar la comunicación ${id}:`,error);
      }

    };

    const handleExtendDeadline = () => {
      if (reason.trim()) {
        extendCommunicationDeadline(id, reason)
            .then((response) => {
              if (response.status === 200) {
                useStore.getState().getComplaints();
                setIsExtendDialogOpen(false);
                setReason("");
                setErrorMessage("");
                window.location.reload();
              } else {
                setErrorMessage(t("messages.extendDeadlineFailure"));
              }
            })
            .catch(() => {
              setErrorMessage(t("messages.extendDeadlineFailure"));
            });
      }
    };

    const handleDownloadPDF = () => {
      downloadPDF(id)
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `communication_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(() => {
            setErrorMessage(t("messages.downloadFailurePDF"));
          });
    };

    const handleDownloadExcel = () => {
      downloadExcel(id)
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `communication_${id}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(() => {
            setErrorMessage(t("messages.downloadFailureExcel"));
          });
    };
    const fetchUsereditUsers = async () => {
      const allUsers = await useStore.getState().getUsers();
      const filteredUsers = allUsers.filter((user) =>
          user.roles.includes("useredit"),
      );
      setUsereditUsers(filteredUsers);
    };

    const handleAssignToUseredit = async () => {
      for (const userId of selectedUsers) {
        try {
          // Llamada directa a la nueva función con el ID del usuario y de la comunicación
          await assignCommunications(userId, id);
        } catch (error) {
          console.error(`Error al asignar la comunicación ${id} al usuario ${userId}:`, error);
        }
      }
      setIsAssignDialogOpen(false);
      setSelectedUsers([]);
      useStore.getState().getComplaints(); // Refrescar datos
    };

    useEffect(() => {
      if (isAssignDialogOpen) {
        fetchUsereditUsers();
      }
    }, [isAssignDialogOpen]);

    useEffect(() => {
      if (errorMessage) {
        const timer = setTimeout(() => setErrorMessage(""), 5000);
        return () => clearTimeout(timer);
      }
    }, [errorMessage]);

    if (isBlocked && !isSuperadmin) {
      return null;
    }

    return (
        <>
          {isBlocked && !isSuperadmin ? null : (
              <DataTableRowActions
                  row={row}
                  actions={[
                    ...(isBlocked && isSuperadmin
                        ? [
                          {
                            label: t("buttonTexts.unblock"),
                            icon: FileText,
                            onClick: handleUnblock,
                          },
                        ]
                        : [
                          {
                            label: t("buttonTexts.edit"),
                            icon: Pencil,
                            onClick: () => handleUpdate(id),
                          },
                          {
                            label: t("buttonTexts.downloadPDF"),
                            icon: FileText,
                            onClick: handleDownloadPDF,
                          },
                          {
                            label: t("buttonTexts.downloadExcel"),
                            icon: FileSpreadsheet,
                            onClick: handleDownloadExcel,
                          },
                          ...(extendedDate === null
                              ? [
                                {
                                  label: t("buttonTexts.extendDeadline"),
                                  icon: Pencil,
                                  onClick: () => setIsExtendDialogOpen(true),
                                },
                              ]
                              : []),
                          ...(canAssignToUseredit
                              ? [
                                {
                                  label: t("buttonTexts.assignToUseredit"),
                                  icon: UserPlus,
                                  onClick: () => setIsAssignDialogOpen(true),
                                },
                              ]
                              : []),
                          {
                            label: t("buttonTexts.block"),
                            icon: Pencil,
                            onClick: () => setIsBlockDialogOpen(true),
                          },
                          ...(isSuperadmin
                              ? [
                                {
                                  label: t("buttonTexts.delete"),
                                  icon: Trash,
                                  onClick: handleDeleteClick,
                                },
                              ]
                              : []),
                        ]),
                  ]}
              />
          )}
          {isDialogOpen && (
              <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <DialogOverlay />
                <DialogContent>
                  <h2>{t("alertDialog.delete.title")}</h2>
                  <p>{t("alertDialog.delete.description")}</p>
                  <div className="flex justify-center gap-4">
                    <button
                        onClick={() => setIsDialogOpen(false)}
                        className="bg-gray-300 px-4 py-2 rounded"
                    >
                      {t("alertDialog.cancel")}
                    </button>
                    <button
                        onClick={confirmDelete}
                        className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      {t("alertDialog.confirm")}
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
          )}
          {isExtendDialogOpen && (
              <Dialog open={isExtendDialogOpen} onOpenChange={setIsExtendDialogOpen}>
                <DialogOverlay />
                <DialogContent>
                  <h2>{t("dialogTexts.extendDeadlineTitle")}</h2>
                  <Input
                      type="text"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      required={true}
                      placeholder={t("dialogTexts.extendDeadlinePlaceholder")}
                      className="mb-4"
                  />
                  {errorMessage && (
                      <p className="text-red-500 mb-4">{errorMessage}</p>
                  )}
                  <Button onClick={handleExtendDeadline} disabled={!reason.trim()}>
                    {t("buttonTexts.accept")}
                  </Button>
                  <Button
                      onClick={() => setIsExtendDialogOpen(false)}
                      className="ml-2"
                  >
                    {t("buttonTexts.cancel")}
                  </Button>
                </DialogContent>
              </Dialog>
          )}
          {isAssignDialogOpen && (
              <Dialog open={isAssignDialogOpen} onOpenChange={setIsAssignDialogOpen}>
                <DialogOverlay />
                <DialogContent>
                  <h2>{t("dialogTexts.assignToUsereditTitle")}</h2>
                  <div className="flex flex-col gap-4">
                    {usereditUsers.map((user) => (
                        <div key={user.id} className="flex items-center gap-2">
                          <Checkbox
                              checked={selectedUsers.includes(user.id)}
                              onCheckedChange={(checked) =>
                                  setSelectedUsers((prev) =>
                                      checked
                                          ? [...prev, user.id]
                                          : prev.filter((id) => id !== user.id)
                                  )
                              }
                              aria-label={`Seleccionar ${user.name}`}
                          />
                          <span>{user.name}</span>
                        </div>
                    ))}
                  </div>
                  <div className="flex justify-end gap-4 mt-4">
                    <Button
                        onClick={handleAssignToUseredit}
                        disabled={selectedUsers.length === 0}
                    >
                      {t("buttonTexts.confirmAssignment")}
                    </Button>
                    <Button onClick={() => setIsAssignDialogOpen(false)}>
                      {t("buttonTexts.cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
          )}
          {isBlockDialogOpen && (
              <Dialog open={isBlockDialogOpen} onOpenChange={setIsBlockDialogOpen}>
                <DialogOverlay />
                <DialogContent>
                  <h2>{t("dialogTexts.confirmBlockTitle")}</h2>
                  <p>{t("dialogTexts.confirmBlockDescription")}</p>
                  <div className="flex justify-center gap-4">
                    <Button
                        onClick={handleBlock}
                        className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      {t("buttonTexts.confirmBlock")}
                    </Button>
                    <Button
                        onClick={() => setIsBlockDialogOpen(false)}
                        className="bg-gray-300 px-4 py-2 rounded"
                    >
                      {t("buttonTexts.cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
          )}
          <Toast message={errorMessage} onClose={() => setErrorMessage("")} />
        </>
    );

  },
};

const generateDynamicColumns = (complaints, selectedLanguage) => {
  const dynamicColumns = [];
  if (complaints.length) {
    complaints.forEach((complaint) => {
      if (!complaint.answers) return;

      complaint.answers.forEach((answer) => {
        const { question, answerText } = answer;

        if (question.visibility?.list) {
          const headerText =
              Array.isArray(question?.texts)
                  ? question.texts.find((text) => text.language === selectedLanguage)?.text || ""
                  : "";


          if (
            !dynamicColumns.some((col) => col.accessorKey === question.alias)
          ) {
            dynamicColumns.push({
              accessorKey: question.alias,
              header: () => (
                <div style={{ padding: "4px", margin: "0" }}>{headerText}</div>
              ),
              cell: ({ row }) => {
                const rowAnswer = row.original.answers?.find(
                  (a) => a.question.alias === question.alias,
                );

                if (!rowAnswer) return <div />;

                if (
                  rowAnswer.question.options &&
                  rowAnswer.question.options.length > 0
                ) {
                  const selectedOption = rowAnswer.question.options.find(
                    (option) => option.value === rowAnswer.answerText,
                  );

                  const optionText =
                      selectedOption && Array.isArray(selectedOption.text)
                          ? selectedOption.text.find((text) => text.language === selectedLanguage)?.text || ""
                          : "";


                  return (
                    <div style={{ padding: "4px", margin: "0" }}>
                      {optionText}
                    </div>
                  );
                }

                return (
                  <div style={{ padding: "4px", margin: "0" }}>
                    {rowAnswer.answerText}
                  </div>
                );
              },
              style: {
                width: "auto",
                whiteSpace: "nowrap",
                padding: "4px",
                margin: "0",
              },
            });
          }
        }
      });
    });
  }
  return dynamicColumns;
};

const handleUpdate = (id) => {
  window.location.href = `/admin/edit/${id}`;
};

export const getColumns = (complaints, selectedLanguage) => {
  const dynamicColumns = generateDynamicColumns(complaints, selectedLanguage);
  return [...baseColumns, ...dynamicColumns, actionsColumn];
};
