import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Toaster } from "@/components/ui/sonner";
import { columns } from "@/components/ui/table/columns/questions";
import { DataTable } from "@/components/ui/table/data-table.jsx";
import Loader from "@/components/utils/loader.jsx";
import { useStore } from "@/store";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateQuestion from "./create-question.jsx";
import UpdateQuestion from "./update-question.jsx";
import {getForms, getQuestionsByForm} from "@/api/forms-questions/forms-questions-services.js";

const FormsQuestions = ({ formType }) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateModal = useStore((state) => state.updateModal);
  const setUpdateModal = useStore((state) => state.setUpdateModal);
  const selectedLanguage = useStore((state) => state.selectedLanguage);

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true); // Asegúrate de mostrar el loader mientras se cargan los datos
      try {
        // Obtener los formularios
        const forms = await getForms();
        // Buscar el formulario según el tipo
        const form = forms.find((f) => f.type === formType);

        if (form?.id) {
          // Obtener las preguntas del formulario
          const response = await getQuestionsByForm(form.id);
          setQuestions(response || []);
        } else {
          console.error(`Formulario no encontrado para el tipo: ${formType}`);
          setQuestions([]); // Si no hay formulario, dejar preguntas vacías
        }
      } catch (error) {
        console.error("Error al cargar las preguntas:", error);
      } finally {
        setLoading(false); // Ocultar el loader al finalizar
      }
    };

    fetchQuestions();
  }, [formType]);


  const formatQuestions = (questions) => {
    return (questions || []).map((question) => {
      // Trazas para depuración
      const texts = question?.texts;
      const language = selectedLanguage;


      const matchedText = Array.isArray(texts)
          ? texts.find((t) => t.language === language)?.text
          : null;


      return {
        id: question?.id || null,
        questions: matchedText || "not text in this language",
        questionType: question?.type || "Unknown",
        possibleAnswers: Array.isArray(question?.options)
            ? question.options
            .map((option) =>
                Array.isArray(option?.text)
                    ? option.text.find((t) => t.language === language)?.text || null
                    : null
            )
            .filter(Boolean)
            .join(", ") || ""
            : "",
        active: question?.active ? "Sí" : "No",
        screenNumber: question?.screen,
      };
    });
  };


  const formattedQuestions = formatQuestions(questions);

  const closeModal = () => {
    setUpdateModal(false);
  };

  return (
      <section className="w-full">
        <Toaster />

        <section className="w-full flex mb-3">
          <Dialog>
            <DialogTrigger asChild={true}>
              <Button className="mt-4 bg-primary hover:bg-primary-600">
                {t("buttonTexts.addNew")} <Plus className="ml-2" />
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
              <DialogHeader>
                <DialogTitle>
                  {t("MainLayout.questionForms.createQuestion")}
                </DialogTitle>
              </DialogHeader>
              <CreateQuestion formType={formType} />
            </DialogContent>
          </Dialog>
        </section>

        <section className="w-full flex">
          <Dialog
              open={updateModal}
              onOpenChange={(isOpen) => setUpdateModal(isOpen)}
          >
            <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
              <DialogHeader>
                <DialogTitle>
                  {t("MainLayout.questionForms.updateQuestion")}
                </DialogTitle>
              </DialogHeader>
              <UpdateQuestion formType={formType} closeModal={closeModal} />
            </DialogContent>
          </Dialog>
        </section>

        {loading ? (
            <div className="flex justify-center">
              <Loader style={"loader"} />
            </div>
        ) : (
            <DataTable
                data={formattedQuestions}
                columns={columns}
                mainFilterColumn="questions"
                textPlaceholder={t("filterTextPlaceholders.question")}
            />
        )}
      </section>
  );
};

export default FormsQuestions;
