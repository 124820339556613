import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";
import { useStore } from "@/store";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const NavBar = ({ companyData }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const loggedInUser = useStore((state) => state.loggedInUser);

  const isAdminRole = () =>
      loggedInUser.roles.some(
          (role) => role === "admin" || role === "superadmin"
      );

  return (
      <nav className="flex flex-1 flex-col space-y-2">
        {siteConfig.mainNav.map((item) => {
          // Ocultar si es adminOnly y el usuario no tiene permisos
          if (item.adminOnly && !isAdminRole()) return null;

          // Ocultar si el elemento es "MainLayout.queries" y hasConsultation es 0
          if (
              item.titleKey === "MainLayout.queries" &&
              !companyData?.hasConsultation
          ) {
            return null;
          }

          const Icon = item.icon;
          const isActive = location.pathname === item.href;

          return (
              <NavLink
                  key={item.href}
                  to={item.href}
                  className={cn(
                      "flex items-center gap-3 rounded-lg p-2 transition-all hover:bg-slate-100/40",
                      isActive && "text-primary",
                      item.disabled && "opacity-30 pointer-events-none"
                  )}
              >
                <Icon className={cn("h-5 w-5", isActive && "text-primary")} />
                <span>{t(item.titleKey)}</span>
              </NavLink>
          );
        })}
      </nav>
  );
};

export { NavBar };
