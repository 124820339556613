"use client";

import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";
import { cn } from "@/lib/utils";

const Avatar = React.forwardRef(({ className, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={cn(
            "relative flex h-9 w-9 shrink-0 overflow-hidden bg-cover rounded-lg",
            className
        )}
        {...props}
    />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef(({ src, alt, className, ...props }, ref) => {
    let absoluteUrl = null;

    // Verifica que src es válido antes de usarlo
    if (typeof src === "string" && src.trim() !== "") {
        absoluteUrl = src.startsWith("http")
            ? src
            : `${window.location.origin}/${src}`;
    } else {
        console.error("AvatarImage src is invalid:", src);
    }

    return absoluteUrl ? (
        <AvatarPrimitive.Image
            ref={ref}
            src={absoluteUrl}
            alt={alt}
            className={cn("h-full w-full object-contain", className)}
            {...props}
            onError={() => console.error("Error loading image:", absoluteUrl)}
        />
    ) : (
        <AvatarPrimitive.Fallback
            ref={ref}
            className={cn(
                "flex h-full w-full items-center justify-center rounded-lg bg-slate-200",
                className
            )}
            {...props}
        >
            {alt ? alt.charAt(0).toUpperCase() : "?"}
        </AvatarPrimitive.Fallback>
    );
});
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={cn(
            "flex h-full w-full items-center justify-center rounded-lg bg-slate-200",
            className
        )}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarFallback, AvatarImage };
